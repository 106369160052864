
@import "../variables.less";
@import "../resource/common.less";
@currentWidth: 750px;
.home2_wrap{
    display:flex;
    flex-wrap:wrap;
    justify-content: space-evenly;
    &>div{
        width: calc(50% - 15px);
        border-radius: 4px;
        background:@contentBg;
        box-shadow: 0 0 10px 1px @boxshadow;
        .home2_title1{
            position:relative;
            .home2_txt{
                position:absolute;
                right: 25px;
                display:flex;
                font-weight: 400;
                font-size: 14px;
                color: @colorGary;
                cursor:pointer;
                p{
                    margin: 0 8px;
                }
            }
        }
    }
    .home2_1, .home2_2{
        height: 38%;
        margin: 10px 0 0 0;
    }
    .home2_3, .home2_4{
        height: calc(62% - 32px);
    }
    .chart_legend_wrap{
        display:flex;    justify-content: end;
        p{
            margin: 8px 15px;
            display:flex;    align-items: center;
            color: @colorGary;
            .chart_legend{
                width: 14px;
                height: 14px;
                border-radius: 8px;
                margin: 0 5px 0 0;
                display: flex;
                outline: 1px solid #ddd;
                justify-content: center;
                align-items: center;
                &:after{
                    content: "";
                    width: 10px;display: inline-block;
                    height: 10px;
                    border-radius: 6px;
                }
            }
            .chart_legend0{
                outline: 1px solid @baseColor;
                &:after{
                    background: @baseColor;
                }
            }
            .chart_legend1{
                outline: 1px solid @colorYellow;
                &:after{
                    background: @colorYellow;
                }
            }
            .chart_legend2{
                outline: 1px solid @colorRed;
                &:after{
                    background: @colorRed;
                }
            }
            .chart_legend3{
                outline: 1px solid @colorGary;
                &:after{
                    background: @colorGary;
                }
            }
        }
    }
    .home2_1{
        display:flex;
        flex-wrap:wrap;
        position:relative;
        .gary_line_class{
            position:absolute;
            top: 41px;
        }
        .home2_chart3_text{
            width: 100%;
            height: 40px;
            position: relative;
            top: 10px;
            font-size: @fontSizeC;
            text-align: right;
        }
        &>div{
            width: 50%;
            height: calc(100% - 60px);
        }
        .chart_wrap{
            position:relative;
            #home_chart1{
                width: 100%;
                height: 100%;
            }
            .baojing{
                position: absolute;
                width: 76px;
                top: calc(50% - 67px);
                left: calc(50% - 39px);
                z-index: 1;
                text-align: center;
                color: @colorGary;
                img{
                    width:40px;
                    margin: 0 0 5px 0;
                }
                p:nth-child(3){
                    margin: 5px 0 0 0;
                }
            }
        }
        .chart_right{
            overflow:auto;
            ul{
                margin-top: 30%;
                li{
                    font-size: 14px;
                    display:flex;
                    align-items: center;
                    margin: 0 0 10px 0;
                    color: @colorGary;    justify-content: space-between;
                    margin-bottom: 60px;    padding-left: 20px;
                    .chart_text{
                        margin: 0 10px;
                        position:relative;
                        &:before{
                            content:"";
                            width: 12px;
                            height:12px;
                            top: 4px;
                            left: -20px;
                            // display: inline-block;
                            background:@baseColor;
                            position:absolute;
                        }
                    }
                    .chart_text1{
                        &:before{
                            background:@colorYellow;
                        }
                    }
                    .chart_text2{
                        text-align: center;
                        margin: 0 10px 0 0;
                    }
                }
            }
        }
    }
    #home_chart2{
        width:100%;
        height:calc(100% - 40px);
        margin-top: 10px;
    }
    .home2_chart3{
        width: 100%;
        height:calc(50% - 30px);
        position:relative;
        .chart3{
            width: 100%;
            height: 100%;
        }
    }
    .home2_chart3_text{
        font-size: @fontSizeC;
        color: @colorGary;
        padding: 5px 10px;
        position:absolute;
        top: 0;
        span{
            color:@baseColor;
        }
    }
    .text_active{
        color:@baseColor!important;
    }
    .home2_4_wrap{
        ul{
            display:flex;
            flex-wrap:wrap;
            height:calc(100% - 60px);
            li{
                width: 45%;
                height: 25%;
                margin-top: 8px;
                text-align: center;
                color: @colorGary;
                img{
                    width: 5vh;
                    height: 5vh;
                }
                .chart_text{
                    span:nth-child(1){
                        color: #3cb058;
                    }
                    span:nth-child(2){
                        color: #c0802d;
                    }
                    span:nth-child(3){
                        color: #de5354;
                    }
                    span:nth-child(4){
                        color: #788CA1;
                    }
                }
            }
        }
    }
}
