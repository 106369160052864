.mainContent {
  width: 750px;
  position: absolute;
  right: 0;
  bottom: 20px;
  top: 100px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 4px;
  pointer-events: all;
  transition: right 2s ease;
  border-radius: 4px 0 0 4px;
}
.mainContent .head {
  line-height: 32px;
  font-size: 18px;
  flex-shrink: 0;
  color: #FFF;
}
.mainContent .head .attach {
  width: 150px;
  float: right;
}
.mainContent .blockTitle {
  font-size: 14px;
  margin-left: 6px;
  border-bottom: 1px solid #FFF;
  color: white;
}
.left_click_btn {
  position: absolute;
  top: 48%;
  left: -18px;
  background: var(--themeColor);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 0px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}
.left_click_btn img {
  width: 18px;
  transform: rotate(180deg);
}
.left_click_btn_active {
  transition: all 2s ease;
}
.left_click_btn_active img {
  transform: rotate(0deg);
}
.mainContent2,
.index {
  position: absolute;
  right: 0;
  top: 60px;
  height: calc(100vh - 60px);
  width: 750px;
  background: rgba(255, 255, 255, 0.9);
  pointer-events: all;
  transition: right 1s ease;
}
.mainContent2 .mian_title,
.index .mian_title {
  font-size: 18px;
  width: 100%;
  padding: 10px 10px;
  display: flex;
  font-weight: 600;
  position: relative;
  margin: 0 0 0 10px;
  line-height: 18px;
}
.mainContent2 .mian_title:before,
.index .mian_title:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 18px;
  top: 11px;
  background: #4261ED;
  left: 1px;
}
.mainContent2 .gary_line,
.index .gary_line {
  width: calc(100% - 20px);
  height: 2px;
  position: relative;
  left: 10px;
  bottom: 0;
  background-color: rgb(247 247 250);
}
.mainContent2 .gary_line::after,
.index .gary_line::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0px;
  background: #e0e2e3;
  right: 0;
}
.mainContent2 .gary_line::before,
.index .gary_line::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0;
  background: #e0e2e3;
  left: 0;
}
.home2_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.home2_wrap > div {
  width: calc(50% - 15px);
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(66, 97, 237, 0.1);
}
.home2_wrap > div .home2_title1 {
  position: relative;
}
.home2_wrap > div .home2_title1 .home2_txt {
  position: absolute;
  right: 25px;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  color: #788CA1;
  cursor: pointer;
}
.home2_wrap > div .home2_title1 .home2_txt p {
  margin: 0 8px;
}
.home2_wrap .home2_1,
.home2_wrap .home2_2 {
  height: 38%;
  margin: 10px 0 0 0;
}
.home2_wrap .home2_3,
.home2_wrap .home2_4 {
  height: calc(62% - 32px);
}
.home2_wrap .chart_legend_wrap {
  display: flex;
  justify-content: end;
}
.home2_wrap .chart_legend_wrap p {
  margin: 8px 15px;
  display: flex;
  align-items: center;
  color: #788CA1;
}
.home2_wrap .chart_legend_wrap p .chart_legend {
  width: 14px;
  height: 14px;
  border-radius: 8px;
  margin: 0 5px 0 0;
  display: flex;
  outline: 1px solid #ddd;
  justify-content: center;
  align-items: center;
}
.home2_wrap .chart_legend_wrap p .chart_legend:after {
  content: "";
  width: 10px;
  display: inline-block;
  height: 10px;
  border-radius: 6px;
}
.home2_wrap .chart_legend_wrap p .chart_legend0 {
  outline: 1px solid #4261ED;
}
.home2_wrap .chart_legend_wrap p .chart_legend0:after {
  background: #4261ED;
}
.home2_wrap .chart_legend_wrap p .chart_legend1 {
  outline: 1px solid #EF9F24;
}
.home2_wrap .chart_legend_wrap p .chart_legend1:after {
  background: #EF9F24;
}
.home2_wrap .chart_legend_wrap p .chart_legend2 {
  outline: 1px solid #FD4848;
}
.home2_wrap .chart_legend_wrap p .chart_legend2:after {
  background: #FD4848;
}
.home2_wrap .chart_legend_wrap p .chart_legend3 {
  outline: 1px solid #788CA1;
}
.home2_wrap .chart_legend_wrap p .chart_legend3:after {
  background: #788CA1;
}
.home2_wrap .home2_1 {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.home2_wrap .home2_1 .gary_line_class {
  position: absolute;
  top: 41px;
}
.home2_wrap .home2_1 .home2_chart3_text {
  width: 100%;
  height: 40px;
  position: relative;
  top: 10px;
  font-size: 14px;
  text-align: right;
}
.home2_wrap .home2_1 > div {
  width: 50%;
  height: calc(100% - 60px);
}
.home2_wrap .home2_1 .chart_wrap {
  position: relative;
}
.home2_wrap .home2_1 .chart_wrap #home_chart1 {
  width: 100%;
  height: 100%;
}
.home2_wrap .home2_1 .chart_wrap .baojing {
  position: absolute;
  width: 76px;
  top: calc(50% - 67px);
  left: calc(50% - 39px);
  z-index: 1;
  text-align: center;
  color: #788CA1;
}
.home2_wrap .home2_1 .chart_wrap .baojing img {
  width: 40px;
  margin: 0 0 5px 0;
}
.home2_wrap .home2_1 .chart_wrap .baojing p:nth-child(3) {
  margin: 5px 0 0 0;
}
.home2_wrap .home2_1 .chart_right {
  overflow: auto;
}
.home2_wrap .home2_1 .chart_right ul {
  margin-top: 30%;
}
.home2_wrap .home2_1 .chart_right ul li {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  color: #788CA1;
  justify-content: space-between;
  margin-bottom: 60px;
  padding-left: 20px;
}
.home2_wrap .home2_1 .chart_right ul li .chart_text {
  margin: 0 10px;
  position: relative;
}
.home2_wrap .home2_1 .chart_right ul li .chart_text:before {
  content: "";
  width: 12px;
  height: 12px;
  top: 4px;
  left: -20px;
  background: #4261ED;
  position: absolute;
}
.home2_wrap .home2_1 .chart_right ul li .chart_text1:before {
  background: #EF9F24;
}
.home2_wrap .home2_1 .chart_right ul li .chart_text2 {
  text-align: center;
  margin: 0 10px 0 0;
}
.home2_wrap #home_chart2 {
  width: 100%;
  height: calc(100% - 40px);
  margin-top: 10px;
}
.home2_wrap .home2_chart3 {
  width: 100%;
  height: calc(50% - 30px);
  position: relative;
}
.home2_wrap .home2_chart3 .chart3 {
  width: 100%;
  height: 100%;
}
.home2_wrap .home2_chart3_text {
  font-size: 14px;
  color: #788CA1;
  padding: 5px 10px;
  position: absolute;
  top: 0;
}
.home2_wrap .home2_chart3_text span {
  color: #4261ED;
}
.home2_wrap .text_active {
  color: #4261ED !important;
}
.home2_wrap .home2_4_wrap ul {
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 60px);
}
.home2_wrap .home2_4_wrap ul li {
  width: 45%;
  height: 25%;
  margin-top: 8px;
  text-align: center;
  color: #788CA1;
}
.home2_wrap .home2_4_wrap ul li img {
  width: 5vh;
  height: 5vh;
}
.home2_wrap .home2_4_wrap ul li .chart_text span:nth-child(1) {
  color: #3cb058;
}
.home2_wrap .home2_4_wrap ul li .chart_text span:nth-child(2) {
  color: #c0802d;
}
.home2_wrap .home2_4_wrap ul li .chart_text span:nth-child(3) {
  color: #de5354;
}
.home2_wrap .home2_4_wrap ul li .chart_text span:nth-child(4) {
  color: #788CA1;
}
